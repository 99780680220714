import { createGlobalStyle } from "styled-components"

import { contP } from "./common.styles"

export const GlobalStyles = createGlobalStyle`


.site-main {
  overflow: hidden;
}

body {
  font-family: ${props => props.theme.fonts.primary};
  color: ${props => props.theme.colors.primary200};
  font-size: 16px;
  font-weight: 300;
}

button {
  outline: none;
  appearance: none;
}

@-ms-viewport {
  width: device-width
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar
}

*,
::after,
::before {
  box-sizing: inherit
}

.container {
  max-width: ${props => props.theme.container + 30}px;
  padding: 0 ${30 / 2}px;
  margin: 0 auto;
}

a {
  display: inline-block;
  vertical-align: middle;
  color: ${props => props.theme.colors.secondary};

  &:hover {
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-family: ${props => props.theme.fonts.secondary};
}

/* Global disable for scroll */
.ReactModal__Body--open {
  overflow: hidden;
  min-height: 100%;
  max-height: 100%;
}

body.modal-open {
  overflow: hidden;
  min-height: 100%;
  max-height: 100%;
}

.has-open-menu {
  ${props => props.theme.max('md', () => (`
    overflow: hidden;
    min-height: 100%;
    max-height: 100%;
  `))}
}

.desktop-only {
  ${props => props.theme.max('sm', () => (`
    display: none !important;
  `))}
}

/* Custom Scrollbar Style */
body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: ${props => props.theme.colors.primary};
  border-radius: 5px;
}


.mobile-only {
  ${props => props.theme.min('sm', () => (`
    display: none !important;
  `))}
}

svg {
  display: inline-block;
  vertical-align: middle;
}

.angle-r-bg,
.angle-r-in {
  transition: 400ms;
}

.btn {
  cursor: pointer;
  appearance: none;
  border: none;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-family: ${props => props.theme.fonts.primary};
  
  .to-animate & {
    .btn-txt {
      transform: translate(10px, 0);
      opacity: 0;
    }

    .svg-wrap {
      opacity: 0;
      transform: scale(0);
    }
  }

  .to-animate:not(.is-in-vp) & {
    .svg-wrap,
    .btn-txt {
      transition-delay: 0ms !important;
    }
  }

  .to-animate.is-in-vp & {
    .btn-txt {
      transition: ${800}ms;
      transform: translate(0);
      opacity: 1;
    }

    .svg-wrap {
      transition: ${800}ms;
      transform: scale(1);
      opacity: 1;
    }
  }

  &.primary {
    color: ${props => props.theme.colors.white};

    &:hover {
      /* TODO: modify hover animation */
      .angle-r-bg {
        fill: ${props => props.theme.colors.white};
      }

      .angle-r-in {
        stroke: ${props => props.theme.colors.primary};
      }
    }
  }
  
  &.secondary {
    font-size: 14px;
    color: ${props => props.theme.colors.primary100};
    font-weight: bold;

    svg {
      width: 50px;
      height: auto;
    }
    
    .angle-r-bg {
      fill: ${props => props.theme.colors.secondary200};
    }

    .angle-r-in {
      stroke: ${props => props.theme.colors.primary100};
    }

    &:hover {
      /* TODO: modify hover animation */
      .angle-r-bg {
        fill: ${props => props.theme.colors.primary};
      }

      .angle-r-in {
        stroke: ${props => props.theme.colors.white};
      }
    }
  }
  
  &.white {
    font-size: 14px;
    color: ${props => props.theme.colors.primary100};
    font-weight: bold;

    svg {
      width: 50px;
      height: auto;
    }
    
    .angle-r-bg {
      fill: ${props => props.theme.colors.white};
    }

    .angle-r-in {
      stroke: ${props => props.theme.colors.primary100};
    }

    &:hover {
      /* TODO: modify hover animation */
      .angle-r-bg {
        fill: ${props => props.theme.colors.primary};
      }

      .angle-r-in {
        stroke: ${props => props.theme.colors.white};
      }
    }
  }

  &.white-text {
    color: #fff;
  }

  span {
    display: inline-block;
    padding-top: ${props => props.theme.fontshift};
  }
  
  svg {
    margin-left: 12px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -${contP / 2}px;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.pad-col {
  padding: 0 ${contP / 2}px;
}

.cutout-top {
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: ${props => props.theme.dimensions.cutoutWpos}%;
    top: -5px;
    width: ${props => 100 - props.theme.dimensions.cutoutWpos + 1}%;
    height: 12.8%;
    min-height: 40px;
    max-height: 140px;
    background-color: ${props => props.theme.colors.white};
    z-index: 1;

    ${props => props.theme.max('sm', (props) => (`
      content: none;
    `), props)}
  }
}

.cutout-bottom {
  &:after {
    content: '';
    display: block;
    position: absolute;
    right: ${props => props.theme.dimensions.cutoutWpos}%;
    bottom: -5px;
    width: ${props => 100 - props.theme.dimensions.cutoutWpos + 1}%;
    height: ${90}px;
    min-height: 40px;
    min-height: ${90}px;
    max-height: 100px;
    background-color: ${props => props.theme.colors.white};
    z-index: 1;
  
    ${props => props.theme.max('sm', (props) => (`
      content: none;
    `), props)}
  }

  &.cutout-secondary300 {
    &:after {
      background-color: ${props => props.theme.colors.secondary300};
    }
  }
}

.cutout-top-primary {
  &:before,
  &:before {
    background-color: ${props => props.theme.colors.primary100};
  }
}

.num-title {
  margin-bottom: .29em;
  font-size: 20px;
  color: ${props => props.theme.colors.primary100};
}

.num-item.has-title {
  margin-bottom: 42px;

  ${props => props.theme.max('sm', (props) => (`
    margin-bottom: 61px;
  `), props)}
}

.legal-title {
  font-size: 106px;
  letter-spacing: -.019em;
  margin-bottom: .55em;

  ${props => props.theme.max('mac', () => (`
    font-size: 98px;
  `))}
  
  ${props => props.theme.max('ls', () => (`
    font-size: 88px;
  `))}
  
  ${props => props.theme.max('md', () => (`
    font-size: 72px;
    line-height: .99;
  `))}

  ${props => props.theme.max('sm', () => (`
    text-align: center;
    margin-bottom: 0.9em;
  `))}
}

.s-to-top-btn {
  ${props => props.theme.min('sm', () => (`
    display: none;
  `))}
  position: fixed;
  bottom: 24px;
  right: 24px;
  appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  padding: 0;
  
  svg {
    width: 40px;
    height: 40px;
    transform: rotate(-90deg);
    
    .angle-r-bg {
      fill: ${props => props.theme.colors.secondary200};
    }
    
    .angle-r-in {
      stroke: ${props => props.theme.colors.primary};
    }
  }
}

.slick-track { 
  cursor: grabbing !important;
}

.sub-section-t {
  margin-bottom: 1em;
}

.preloader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999999999;
  background-color: ${props => props.theme.colors.primary};
  
  &.hidden {
    transform-origin: left top;
    transition: 
      opacity 300ms ease,
      transform 5ms ease 300ms;
    opacity: 0;
    transform: scale(0);
  }
  
  svg {
    margin-bottom: 30px;
  }
}

@keyframes fillbar {
  0% {
    transform: scale(0, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

.loading-bar {
  width: 90%;
  max-width: 300px;
  background-color: rgba(0, 0, 0, .8);

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: ${props => props.theme.colors.secondary};
    animation: fillbar 900ms;
    transform-origin: left center;
  }
}

.back-btn {
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
  background: none;
  border: none;
  font-weight: 300;
  padding: 0;

  svg {
    max-width: 12px;
    margin-right: .8em;
    transform: translateX(.4em);
    transition: 400ms;
    
    path {
      stroke-width: 1.4;
      stroke: ${props => props.theme.colors.primary};
    }
  }

  &:hover {
    text-decoration: none;
    
    svg {
      transform: translateX(.1em);
    }
  }
}

.rect-btn {
  cursor: pointer;
  appearance: none;
  border-radius: 0;
  border: none;
  background: ${props => props.theme.colors.primary100};
  color: #fff;
  padding: 13px 44px;
  font-weight: bold;
  font-size: 14px;
  transition: 400ms;

  &:hover {
    opacity: .8;
  }
}

.link-btn {
  cursor: pointer;
  display: inline-block;
  appearance: none;
  background: none;
  border: none;
  text-decoration: underline;
  transition: 400ms;

  &:hover {
    text-decoration: none;
  }
}

.link-btn-cta {
  font-size: 14px;
  opacity: .5;
}

.uppercase {
  text-transform: uppercase;
}
`