import styled from "styled-components"

import { col } from "./common.styles"

export const HeaderMain = styled.header`
position: fixed;
top: 0;
width: 100%;
transition: all 300ms;
z-index: 9999999;
display: flex;
align-items: center;
padding: 49px 49px;

&.logo-digital-assets {
  .header-logo > svg {
    transform: translate(0, 31%);
  }
}

.header-login-btn {
  ${props => props.theme.min('md', () => (`
    position: relative;
    padding-left: 20px;
    transform: translate(0, -100%);
    opacity: 0;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 1px;
      height: 100%;
      transform: translate(0, -50%);
      opacity: .2;
    }
    
  `))}

  ${props => props.theme.min('lg', () => (`
    padding-left: 40px;
  `))}
}

&.default {
  ${props => props.theme.min('md', (props) => (`
    .header-login-btn:before {
      background-color: ${props.theme.colors.white};
    }
  `), props)}
}

&.white {
  ${props => props.theme.min('md', (props) => (`
  .header-login-btn:before {
    background-color: ${props.theme.colors.primary};
  }
  `), props)}
}


${props => props.theme.max('lg', (props) => (`
  padding: 40px;
`), props)}

${props => props.theme.max('md', (props) => (`
  padding: 22px;
  
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    text-decoration: none;
  }
`), props)}

&:before {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 14;
  left: 0;
  top: 0;
}

&.default {
  &:before {
    background-color: ${props => props.theme.colors.primary};
  }
}

&.white {
  .header-logo {
    svg {
      path {
        fill: ${props => props.theme.colors.primary};
      }
    }
  }
  
  &:before {
    background-color: ${props => props.theme.colors.white};
  }
}

.header-inner {
  ${props => props.theme.min('md', (props) => (`
    display: flex;
    align-items: center;
    position: relative;
    z-index: 15;
  `), props)}
}

${props => props.theme.max('md', (props) => (`
  .header-inner {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: calc(100vh - 79px);
    transform: translate(0, -100%);
    z-index: 3;
    transition: 400ms;
    overflow: auto;
    padding: 45px 22px;
    background-color: ${props.theme.colors.primary};

    p {
      font-size: 12px;
      color: #fff;
      opacity: .5;
      margin-bottom: .4em;
    }
  }

  &.menu-open {
    .header-inner {
      transform: translate(0, -5px);
    }
  }
`), props)}

/* This will hide header but also prevent it from getting hidden when mobile menu is open */
&.hidden-header:not(.menu-open) {
  transform: translate(0, -101%);
}

.header-logo {
  margin-right: auto;
  vertical-align: middle;
  z-index: 15;

  opacity: 0;
  transform: translate(0, -100%);

  svg {
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  ${props => props.theme.max('lg', (props) => (`
    max-width: 200px;
  `), props)}

  ${props => props.theme.max('md', (props) => (`
    max-width: 168px;
  `), props)}
}

&.header-loaded {
  .menu-btn,
  .header-login-btn,
  .header-logo {
    transform: translate(0,0);
    transition: 400ms;
    opacity: 1;
  }
}

&.default {
  .menu-btn {
    span {
      background-color: #fff;
    }
  }
}

&.white {
  .menu-btn {
    span {
      background-color: ${props => props.theme.colors.primary};
    }
  }
}

.menu-btn {
  display: none;
  cursor: pointer;
  position: relative;
  border: none;
  background: none;
  appearance: none;
  border-radius: 0;
  width: ${30 + 10}px;
  height: 40px;
  z-index: 15;

  opacity: 0;
  transform: translate(0, -100%);

  ${props => props.theme.max('md', (props) => (`
    display: block;
  `), props)}

  span {
    position: absolute;
    display: block;
    height: 1.4px;
    width: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 400ms;

    &:nth-child(1) {
      top: calc(50% - 8px);
    }
    &:nth-child(3) {
      top: calc(50% + 8px);
    }
  }
}

&.menu-open {
  .menu-btn {
    span {
      &:nth-child(1) {
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:nth-child(2){
        transform: translate(-100%, -50%) scale(0, 1);
      }

      &:nth-child(3) {
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}

.mobile-nav {
  display: none;
  
  ${props => props.theme.max('md', (props) => (`
    display: block;
    padding-top: 72px;

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 25px;
    }

    li {
      ${col(37)}
      padding-bottom: 25px;
    }

    a {
      color: #fff;
      opacity: .5;
      font-size: 12px;
    }
  `), props)}
}

.btn {
  &.secondary {
    font-size: 16px;
    font-weight: 400;
  }

  ${props => props.theme.max('md', () => (`
    color: #fff !important;
    font-size: 16px !important;

    .angle-r-bg {
      fill: ${props.theme.colors.primary100} !important;
    }

    .angle-r-in {
      stroke: #fff !important;
    }
  `))}
}
`

export const HeaderNav = styled.div`
a {
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    opacity: 0;
  }
}

.default & {
  a {
    color: ${props => props.theme.colors.white};
    opacity: .4;

    &:after {
      background-color: ${props => props.theme.colors.white};
    }

    &.active {
      opacity: 1;
    
      &:after {
        opacity: .2;
      }
    }

    &:hover {
      opacity: 1;
    }
  }
}

${props => props.theme.min('md', (props) => (`
  .white & {
    a {
      color: ${props.theme.colors.primary100};

      &:hover {
        opacity: .5;
      }
    }
  }

  ul {
    margin: 0;
    position: relative;
    list-style: none;
    display: flex;
    padding: 0;
    padding-right: 10px;
    
    li {
      padding: ${props.theme.fontshift} 7px 0;
      opacity: 0;
      transform: translate(0, -100%);

      .header-loaded & {
        transition: 400ms;
        transform: translate(0, 0);
        opacity: 1;
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      width: 1px;
      height: 100%;
      transform: translate(0, -50%);
      opacity: .2;
    }
  }

  a {
    position: relative;
    text-decoration: none;
    padding: 5px;
    transition: 400ms;
  }
`), props)}

${props => props.theme.min('lg', (props) => (`
  ul {
    padding-right: 20px;

    li {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`), props)}

${props => props.theme.max('md', (props) => (`
  border-bottom: 1px solid rgba(255,255,255,.2);
  padding-bottom: 31px;
  margin-bottom: 31px;

  li {
    padding-bottom: 12px;
  }

  a {
    color: #fff;
    font-size: 22px;
    line-height: 2.28;
    display: inline-block;
  }
`), props)}
`
