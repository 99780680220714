import React from "react"

import AngleRight from "../assets/svg/angle-left.svg"
import HeaderLogo from "../elements/header-logo"

export const Modal = ({ children, onBackClick, modalClass = '' }) => {
  return (
    <div className={`modal ${modalClass}`}>
      <div className="modal-header pad-right">
        {typeof onBackClick !== 'undefined' &&
          <button className="back-btn" onClick={() => {
            onBackClick()
          }}>
            <AngleRight />
            {`Back`}
          </button>
        }

        <div className="modal-logo-wrap">
          <HeaderLogo />
        </div>
      </div>

      <div className="modal-main">{children}</div>
    </div>
  )
}