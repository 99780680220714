import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { ModalGroupMain } from "../styles/modal-group.style"
import { Modal } from "./modal"
import { TextPageContent } from "../elements/modal-text-page-content"
import { ModalContactContent } from "../elements/modal-contact-content"
import { useAgreementDispatch } from "../context/global-agreement-context"
import SimpleModalContent from "../elements/simple-modal-content"

export const ModalGroup = ({ onAccept = () => undefined }) => {
  const {
    wpgraphql: {
      privacyData,
      disclaimerData,
      aboutUsPageData,
      bhDigitalData,
      themeSettings: {
        themesettingsacf
      }
    }
  } = useStaticQuery(graphql`
    query {
      wpgraphql {
        aboutUsPageData: page(id: "49", idType: DATABASE_ID) {
          aboutusacf {
            tradeStructuringFootnotes {
              footnoteSymbol
              linkText
              modalCloseButton
              text
              modalContent {
                text
                title
              }
            }
          }
        }

        bhDigitalData: page(id: "1323", idType: DATABASE_ID) {
          digitalassetsacf {
            modalFooterTitle
            modalFooterText
          }
        }

        privacyData: page(id: "94", idType: DATABASE_ID) {
          title
          textpageacf {
            introContent {
              contentType
              wysiwyg
              title
              text
            }
            sections {
              contentType
              wysiwyg
              text
              title
            }
          }
        }

        disclaimerData: page(id: "87", idType: DATABASE_ID) {
          title
          textpageacf {
            introContent {
              contentType
              wysiwyg
              title
              text
            }
            sections {
              contentType
              wysiwyg
              text
              title
            }
          }
        }

        themeSettings {
          themesettingsacf {
            contactIrEmail
            contactIrLabel
            otherEnquiriesEmail
            otherEnquiriesLabel
            otherEnquiriesPhoneLink
            otherEnquiriesPhoneText
            pressEnquiriesGroupName
            pressEnquiriesLabel
            pressEnquiriesUkEmail
            pressEnquiriesUkEmailText
            pressEnquiriesUkPhoneLink
            pressEnquiriesUsaEmail
            pressEnquiriesUkPhoneText
            pressEnquiriesUsaEmailText
            pressEnquiriesUsaPhoneLink
            pressEnquiriesUsaPhoneText

            disclaimerRejectButtonText
            disclaimerCookieFooterTitle
            disclaimerCookieFooterContent
            disclaimerCookieFooterLinkText
            disclaimerAcceptButtonText
            disclaimerContactSubtitle
            disclaimerContactTitle
            disclaimerCookiesTitle

            disclaimerModalTitle
            prependDisclaimerContent {
              text
              title
            }
          }
        }
      }
    }
  `);

  const isSSR = typeof window === "undefined";

  const [popupTermsStatus, setPopupTermsStatus] = React.useState(isSSR ? false : window.localStorage.getItem('bh-disclosureNoticesStatus'));

  const setAgreement = useAgreementDispatch();

  const additionalData = React.useRef({ returnToStep: 'additional' });
  const [currentStep, setCurrentStep] = React.useState('additional');
  
  /* if (typeof window !== 'undefined') {
    if (popupTermsStatus !== 'accepted') {
      document.body.classList.add('modal-open');
    }
  }  */ 

  if (!isSSR && typeof onAccept === 'undefined') {
    if (popupTermsStatus !== 'accepted') {
      document.body.classList.add('modal-open');
    } else {
      
     
        document.body.classList.remove('modal-open');
      
     
    }
  }

  function handleAccept() {


    const status = 'accepted';

  

    if (!isSSR) {
      window.localStorage.setItem('bh-disclosureNoticesStatus', status);
      document.body.classList.remove('modal-open');
    }
    setPopupTermsStatus(status);
    setAgreement({ type: 'ACCEPT_TERMS' })
    onAccept();
  }

  function handleAcceptFirstStep() {
    additionalData.current.returnToStep = 'additional';
    setCurrentStep('additional')
  }

  const prependedContent = (themesettingsacf?.prependDisclaimerContent || []).map((item) => {
    return {
      contentType: null,
      text: "",
      title: "",
      wysiwyg: null,
      ...item
    }
  })

  const content = {
    ...(disclaimerData.textpageacf || {}),
    introContent: [
      ...prependedContent,
      ...(disclaimerData?.textpageacf?.introContent || [])
    ]
  };

  const additionalContent = aboutUsPageData?.aboutusacf?.tradeStructuringFootnotes.map(({ modalContent }) => {
    return {
      title: modalContent?.title,
      sections: [
        {
          title: '',
          text: modalContent?.text,
        }
      ]
    }
  })

  // alert(popupTermsStatus);

  return (

    <React.Fragment>

      {(popupTermsStatus !== 'accepted' && !isSSR) &&
        <ModalGroupMain>
          {currentStep === 'disclaimer' &&
            <Modal hasBackButton={false} modalClass={"modal-sm modal-has-actions"}>

              <TextPageContent setCurrentStep={setCurrentStep} data={content} separateIntroContent={true} />

              <div className="modal-actions-wrap">
                <button className="rect-btn" onClick={handleAcceptFirstStep}>
                  {themesettingsacf?.disclaimerAcceptButtonText}
                </button>
                <button className="link-btn link-btn-cta" onClick={() => setCurrentStep('contact')}>
                  {themesettingsacf?.disclaimerRejectButtonText}
                </button>
              </div>

              <div className="modal-footer-wrap">
                <h3 className="modal-footer-title">
                  {themesettingsacf?.disclaimerCookieFooterTitle}
                </h3>
                <p className="modal-footer-content">
                  {themesettingsacf?.disclaimerCookieFooterContent}
                  <button className="link-btn" onClick={() => setCurrentStep('privacy')}>
                    {themesettingsacf?.disclaimerCookieFooterLinkText}
                  </button>
                </p>
              </div>
            </Modal>
          }

          {currentStep === 'additional' &&
            <Modal hasBackButton={false} modalClass={"modal-sm modal-has-actions"}>

              <SimpleModalContent items={additionalContent} hideSectionNumbers={true} />

              <div className="modal-actions-wrap">
                <button className="rect-btn" onClick={handleAccept}>
                  {themesettingsacf?.disclaimerAcceptButtonText}
                </button>
                <button className="link-btn link-btn-cta" onClick={() => setCurrentStep('contact')}>
                  {themesettingsacf?.disclaimerRejectButtonText}
                </button>
              </div>

              <div className="modal-footer-wrap">
                <h3 className="modal-footer-title">
                  {bhDigitalData?.digitalassetsacf?.modalFooterTitle || ''}
                </h3>
                <p className="modal-footer-content">
                  {bhDigitalData?.digitalassetsacf?.modalFooterText || ''}
                </p>
              </div>
            </Modal>
          }

          {currentStep === 'contact' &&
            <Modal
              onBackClick={() => {
                setCurrentStep(additionalData?.current?.returnToStep);
              }}
            >
              <h2 className="intro-title">
                {themesettingsacf?.disclaimerContactTitle}
              </h2>
              <p className="modal-subtitle">
                {themesettingsacf?.disclaimerContactSubtitle}
              </p>
              <div className="modal-content">
                <ModalContactContent contactDetails={themesettingsacf} />
              </div>
            </Modal>
          }

          {currentStep === 'privacy' &&
            <Modal
              modalClass={"modal-cookies"}
              onBackClick={() => {
                setCurrentStep(additionalData?.current?.returnToStep);
              }}
            >
              <h2 className="intro-title">
                {themesettingsacf?.disclaimerCookiesTitle}
              </h2>

              <TextPageContent setCurrentStep={setCurrentStep} data={privacyData.textpageacf} />
            </Modal>
          }

        </ModalGroupMain>
      }
    </React.Fragment >
  )
 
}
