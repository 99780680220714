import styled from "styled-components"

import { col, calcCol, containerSideOffset } from "./common.styles"

export const FooterMain = styled.footer`
padding: 149px 0 208px;
background-color: ${props => props.theme.colors.primary};
color: ${props => props.theme.colors.white};
margin-top: -3px;

${props => props.theme.max('sm', (props) => (`
  text-align: center;
  padding: 67px 0 55px;
`), props)}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.main-col {
  padding-top: 19px;
  padding-bottom: 15px;
  ${containerSideOffset('left', 3)}
  padding-right: ${calcCol(1)};
  ${col(9, 'c')}
  border-right: 1px solid rgba(255, 255, 255, .15);

  ${props => props.theme.max('mac', (props) => (`
    ${col(43)}
  `), props)}

  ${props => props.theme.max('md', (props) => (`
    ${col(50)}
  `), props)}

  ${props => props.theme.max('sm', (props) => (`
    position: relative;
    margin-left: 0;
    ${col(100)}
    border-right: none;
    padding: 24px;
    padding-bottom: 64px;
    margin-bottom: 52px;
    
    &:after {
      content: '';
      border-bottom: 1px solid rgba(255, 255, 255, .15);
      position: absolute;
      display: block;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 50%;
    }
  `), props)}
}

.footer-logo {
  ${col(100)}
  margin-bottom: 64px;

  ${props => props.theme.max('sm', (props) => (`
    margin-bottom: 60px;
  `), props)}

  svg {
    width: 100%;
    height: auto;
  }
}

.side-col {
  padding-top: 10px;
  margin-right: auto;
  ${containerSideOffset('right', 3)}
  ${col(9, 'c')}
  padding-left: ${calcCol(1)};
  margin-left: auto;

  ${props => props.theme.max('md', () => (`
    margin-right: auto;
  `))}

  ${props => props.theme.max('sm', (props) => (`
    margin-right: 0;
    ${col(100)}
    padding: 24px;
  `), props)}
}

.side-col-inner {
  margin-left: auto;
  max-width: 474px;

  ${props => props.theme.max('sm', (props) => (`
    max-width: 100%;
  `), props)}
}

.main-col-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 392px;

  ${props => props.theme.max('sm', (props) => (`
    max-width: 100%;
    display: block;
  `), props)}
}

.footer-group-title {
  font-size: 14px;
  margin-bottom: 0.2em;
}

.footer-group {
  margin-bottom: 28px;

  a,
  span {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.6;
    color: ${props => props.theme.colors.white};
    font-size: 14px;
    opacity: .5;
  }
}

.main-links {
  a {
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    font-size: 14px;
    color: ${props => props.theme.colors.white};
    padding: 5px 0;

    &.active {
      text-decoration: underline;
    }
    
    ${props => props.theme.max('sm', (props) => (`
      margin-bottom: 16px;
    `), props)}
  }

  &.footer-nav>* {
    &:last-child {
      color: #56BBB1;
      a {
        color: #56BBB1;
      }
    }
  }
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.additional-links {
  margin-bottom: 57px;

  &.left {
    min-width: 50%;

    ${props => props.theme.max('sm', (props) => (`
      margin-bottom: 0;
      padding-top: 34px;
    `), props)}
  }  

  a {
    display: inline-block;
    line-height: 1.9;
    font-size: 12px;
    color: ${props => props.theme.colors.white};
    opacity: .5;
    transition: 400ms;

    &:hover,
    &.active {
      text-decoration: underline;
      opacity: 1;
    }

    ${props => props.theme.max('sm', (props) => (`
      margin-bottom: 17px;
    `), props)}
  }
}

.footer-nav {
  min-width: 50%;
  margin-bottom: 95px;

  ${props => props.theme.max('sm', (props) => (`
    margin-bottom: 40px;
  `), props)}
}

.s-box {
  svg {
    margin-right: 5px;
  }
}

.copy-text {
  font-size: 12px;
  color: ${props => props.theme.colors.white};
  opacity: .5;

  a {
    color: ${props => props.theme.colors.white};
    vertical-align: initial;
  }
}

.sync {
  min-width: 25.2%;
}

.footer-title {
  font-size: 36px;

  ${props => props.theme.max('sm', (props) => (`
    margin-bottom: 40px;
  `), props)}
}

.footer-subgroup {
  margin-bottom: 22px;
}


.mobile {
  ${props => props.theme.min('sm', (props) => (`
    display: none;
  `), props)}
}

.desktop {
  ${props => props.theme.max('sm', (props) => (`
    display: none;
  `), props)}
}
`