import styled from "styled-components"
import { col, SectionTextMain } from "./common.styles"

const modalHeaderH = 12.8;

export const ModalGroupMain = styled.div`
position: fixed;
z-index: 99999999;
top: 0;
left: 0;
bottom: 0;
left: 0;
height: 100%;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
background-color: rgba(0,0,0,.1);

.modal {
  position: relative;
  z-index: 99;
  background-color: #fff;
  height: 90%;
  max-height: 700px;
  width: 90%;
  max-width: 1000px;

  @media (max-width: 768px), (max-height: 600px) {
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    overflow-y: auto;
  }
}

.modal-footer-wrap,
.modal {
  padding-left: 60px;
  padding-right: 20px;

  @media (max-width: 768px), (max-height: 600px) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.pad-right {
  padding-right: 40px;
  
  @media (max-width: 768px), (max-height: 600px) {
    padding-right: 0;
  }
}

.modal-logo-wrap {
  display: flex;
  max-width: 236px;
  align-items: center;
  margin-left: auto;

  ${props => props.theme.max('mb', () => (`
    margin-left: 0;
    margin-bottom: 30px;
  `))}

  svg {
    width: 100%;
    ${col(100)}

    path {
      fill: ${props => props.theme.colors.primary};
    }
  }
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 90px;

  ${props => props.theme.max('mb', () => (`
    height: auto;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-end;
    padding-top: 30px;
    padding-bottom: 30px;
  `))}
}

.modal-cookies {
  .modal-main {
    max-height: 87%;
    padding-bottom: 40px;
  }
}

.modal-sm {
  .modal-main {
    padding-bottom: 150px;
    
    @media (max-width: 768px), (max-height: 600px) {
      padding-bottom: 0;
    }
  }
}

.modal-h-md {
  @media (min-width: 768px) {
    @media  (min-height: 600px) {
      max-height: 600px;
    }
  }
}

.modal-h-sm {
  @media (min-width: 768px) {
    @media  (min-height: 600px) {
      max-height: 530px;
    }
  }
}

.modal-has-actions {
  .modal-main {
    @media (max-width: 768px), (max-height: 600px) {
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100px;
        flex: 0 0 100px;
      }
    }
  }
}

.modal-main {
  display: flex;
  flex-direction: column;
  max-height: ${100 - modalHeaderH - 3}%;
}

.modal-content {
  overflow-y: auto;
  padding-right: 38px;

  @media (max-width: 768px), (max-height: 600px) {
    overflow-y: initial;
    padding-right: 0;
  }

  ${SectionTextMain} {
    font-size: 14px;
  }

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000;
    outline: none;
    border-radius: 0;
  }

  &::-webkit-scrollbar-track {
    box-shadow: #DEDEDE 0px 0px 6px inset;
  }
}

.modal-actions-wrap {
  position: relative;
  z-index: 2;
  padding-top: 24px;
  padding-bottom: 35px;
  
  @media (max-width: 768px), (max-height: 600px) {
    position: fixed;
    background-color: #fff;
    z-index: 5;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 30px 30px;
    border-top: 1px solid rgba(33, 41, 62,.3);
    height: 110px;
  }
}

.link-btn,
.rect-btn {
  max-height: 90%;
}

.rect-btn {
  margin-right: 20px;

  ${props => props.theme.max('xs', () => (`
    padding-left: 30px;
    padding-right: 30px;
  `))}
}

.modal-footer-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  background-color: ${props => props.theme.colors.secondary300};
  position: absolute;
  padding-top: 30px;
  padding-bottom: 30px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 175px;

  @media (max-width: 768px), (max-height: 600px) {
    position: relative;
    bottom: 0;
    left: 0;
    height: auto;
    width: auto;
    margin-left: -30px;
    margin-right: -30px;
  }
}

.intro-title {
  font-size: 50px;
  line-height: 1;
  margin-bottom: .5em;

  ${props => props.theme.max('xs', () => (`
    font-size: 42px;
  `))}
}

.section-title {
  font-family: ${props => props.theme.fonts.primary};
  font-size: 18px;
}

.sm-s-title {
  .intro-s-title {
    font-size: 16px;
    margin-bottom: 18px;
  }
  .s-title {
    font-size: 14px;
    font-weight: bold;
  }
}

.modal-subtitle {
  margin-bottom: 80px;
  line-height: ${26 / 16};

  ${props => props.theme.max('lt', () => (`
    margin-bottom: 40px;
  `))}
}

.modal-footer-title {
  text-transform: uppercase;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 18px;
}

.modal-footer-content {
  .link-btn {
    padding: 0;
  }
}
.modal-footer-content,
.modal-footer-content .link-btn {
  font-size: 14px;
  line-height: ${20 / 14};
  font-weight: 400;
  color: ${props => props.theme.colors.primary100};
}

.back-btn {
  margin-left: -10px;
}

.first-intro {
  ${SectionTextMain} {
    p {
      margin-bottom: 1.5em;
    }
  }
}
`