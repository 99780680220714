import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"

import { LocalLink } from "../elements/link"
import { BtnInner } from "../elements/btn-inner"
import { attribufyString, seoClassPrefix, processMenuArray } from "../helpers/common"

import { HeaderMain, HeaderNav } from "../styles/header.styles"

import { usePreloaderState } from "../context/global-preloader-context"
import HeaderLogo from "../elements/header-logo"
import { useAgreementState } from "../context/global-agreement-context"

import DALogo from "../assets/svg/bh-digital-logo.svg"

const Header = ({ headerColor, logo, modalStateOverride }) => {
  const { preloaded } = usePreloaderState();

  const [hamhurgerState, setHamurgerState] = useState({ open: false });

  const [hiddenHeader, setHiddenHeader] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let lastScrollTop = 0;
      function handleScroll() {
        const st = window.pageYOffset || document.documentElement.scrollTop;
        if (st < lastScrollTop || window.pageYOffset < 10) {
          setHiddenHeader(false);
          document.body.classList.remove('has-hidden-header');
        } else {
          setHiddenHeader(true);
          document.body.classList.add('has-hidden-header');
        }
        lastScrollTop = st;
      }
      window.addEventListener('scroll', handleScroll, false);
      return () => window.removeEventListener('scroll', handleScroll, false);
    }
  }, [])

  useEffect(() => {
    if (hamhurgerState.open) {
      document.body.classList.add('has-open-menu');
    } else {
      document.body.classList.remove('has-open-menu');
    }
  }, [hamhurgerState])

  const { wpgraphql: { themeSettings: { themesettingsacf: { globalLoginLink, headerMenu, globalLoginLinkText, footerLinks1, footerLinks2 } } } } = useStaticQuery(graphql`
    query {
      wpgraphql {
        themeSettings {
          themesettingsacf {
            globalLoginLink
            globalLoginLinkText
            headerMenu {
              label
              url
            }
            footerLinks2 {
              label
              url
            }
            footerLinks1 {
              label
              url
            }
          }
        }
      }
    }
  `)

  const menuNodes = headerMenu || [
    {
      url: "/about-us/",
      label: "About Us",
    },
    {
      url: "/culture/",
      label: "Culture",
    },
    {
      url: "/careers/",
      label: "Careers",
    },
    {
      url: "/contact/",
      label: "Contact",
    },
  ];

  const secondaryLinks = processMenuArray(footerLinks1) || [
    {
      url: "/privacy-policy/",
      label: "Privacy Policy",
    },
    {
      url: "/terms-of-use/",
      label: "Terms of Service",
    }
  ];


  const tertiaryLinks = processMenuArray(footerLinks2) || [
    {
      external: true,
      url: "https://www.brevanhoward.com/login/request-access/",
      label: "Request Access",
    },
    {
      url: "/notices/",
      label: "Notices",
    }
  ];
  function handleHamburgerClick() {
    setHamurgerState({ open: !hamhurgerState.open });
  }

  const { popupTermsStatus } = useAgreementState();

  return (
    <HeaderMain className={classNames(
      headerColor || 'default',
      `logo-${logo}`,
      {
        'header-loaded': !preloaded && ('accepted' === popupTermsStatus || modalStateOverride),
        'hidden-header': hiddenHeader === true,
        'menu-open': hamhurgerState.open === true,
      })} >

      <LocalLink className={`header-logo ${seoClassPrefix}header-logo-link`} to={'/'}>
        {logo === 'digital-assets' ? <DALogo /> : <HeaderLogo />}
      </LocalLink>

      <div className="header-inner">
        <HeaderNav>
          <ul className="desktop">
            {menuNodes.map(({ url, label }, i) => {
              return (
                <li key={i} style={{ transitionDelay: `${100 + i * 80}ms` }}>
                  <LocalLink to={url} className={`${seoClassPrefix}${attribufyString(label)}-link`}>
                    {label}
                  </LocalLink>
                </li>
              )
            })}
          </ul>
        </HeaderNav>

        <div className="header-login-btn" style={{ transitionDelay: `${100 + menuNodes.length * 80}ms` }}>
          <a href={globalLoginLink} className={classNames('btn', `${seoClassPrefix}header-login-link`, headerColor === 'white' ? 'secondary' : 'primary')}>
            <BtnInner text={globalLoginLinkText || "Login"} />
          </a>
        </div>

        <div className={"mobile-nav"}>
          {/* TODO: replace with wp theme settings data */}
          <ul>

            {secondaryLinks.map(({ external, label, url }, i) => {
              const className = `${seoClassPrefix}${attribufyString(label)}-header-link`;
              return (
                <li key={i}>
                  {React.createElement(
                    external === true ? 'a' : LocalLink,
                    external === true ? { href: url, target: "_blank", rel: "noreferrer", className } : { to: url, className },
                    label
                  )}
                </li>
              )
            })}

            {tertiaryLinks.map(({ external, label, url }, i) => {
              const className = `${seoClassPrefix}${attribufyString(label)}-header-link`;
              return (
                <li key={i}>
                  {React.createElement(
                    external === true ? 'a' : LocalLink,
                    external === true ? { href: url, target: "_blank", rel: "noreferrer", className } : { to: url, className },
                    label
                  )}
                </li>
              )
            })}
          </ul>

          <p>© {new Date().getFullYear()} Brevan Howard.</p>
          <p>All rights reserved. </p>
        </div>
      </div>

      <button className="menu-btn" onClick={handleHamburgerClick}>
        <span></span>
        <span></span>
        <span></span>
      </button>

    </HeaderMain>
  )
}

export default Header