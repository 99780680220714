import React from "react"
import { ContactItem } from "../components/contact/contact-information-section"
import { ContactList } from "../styles/contact/contact-information.styles"

export const ModalContactContent = ({
  contactDetails: {
    contactIrEmail,
    contactIrLabel,
    otherEnquiriesEmail,
    otherEnquiriesLabel,
    pressEnquiriesGroupName,
    pressEnquiriesLabel,
    pressEnquiriesUkEmail,
    pressEnquiriesUkEmailText,
    pressEnquiriesUsaEmail,
    pressEnquiriesUkPhoneText,
    pressEnquiriesUsaEmailText,
    pressEnquiriesUsaPhoneText,
  }
}) => {
  const classPrefix = '';
  return (
    <ContactList className="list">
      <ul className="list-container list-container-modal">

        <ContactItem index={0} label={contactIrLabel}>
          <li>
            <a className={`${classPrefix}contact-ir-email`} href={`mailto:${contactIrEmail}`}>{contactIrEmail}</a>
          </li>
        </ContactItem>

        <ContactItem index={2} label={otherEnquiriesLabel}>
          <li>
            <a className={`${classPrefix}contact-other-enq-email`} href={`mailto:${otherEnquiriesEmail}`}>{otherEnquiriesEmail}</a>
          </li>
        </ContactItem>

        <ContactItem index={1} label={pressEnquiriesLabel}>
          <li className="sub-section-t">
            {pressEnquiriesGroupName}
          </li>
          <li>
            <span>UK {pressEnquiriesUkPhoneText}</span>
          </li>
          <li>
            <a className={`${classPrefix}contact-uk-email`} href={`mailto:${pressEnquiriesUkEmail}`}>{pressEnquiriesUkEmailText}</a>
          </li>
          <li>
            <span>USA {pressEnquiriesUsaPhoneText}</span>
          </li>
          <li>
            <a className={`${classPrefix}contact-usa-email`} href={`mailto:${pressEnquiriesUsaEmail}`}>{pressEnquiriesUsaEmailText}</a>
          </li>
        </ContactItem>

      </ul>
    </ContactList>
  )
}
