import React from "react"

import TitleHexagon from "../assets/svg/title-hexagon.svg"
import { HexagonTitleMain } from "../styles/common.styles"

export const HexagonTitle = (props) => {
  if (!props.children) {
    return (
      <React.Fragment></React.Fragment>
    )
  }

  const newProps = { ...props, className: `${props?.className} ${props?.variation === 'circle' ? 'hex-is-circle' : ''}` };

  return (
    <HexagonTitleMain {...newProps}>
      <span className="hexagon-wrap">
        <span className="hexagon-num">{props.number}</span>
        {props?.variation === 'circle' ? <span className="hex-circle"></span> : <TitleHexagon />}
      </span>
      <span className="hexagon-text">{props.children}</span>
    </HexagonTitleMain>
  )
}
