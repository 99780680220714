import React from "react"
import { Link } from "gatsby"

// This component will make it easier to later replace the link if animation between pages needs to be implemented
export const LocalLink = (props) => {
  const wpDomain = process.env.WORDPRESS_HEADLESS_DOMAIN || '';
  const to = props.to.replace(wpDomain, '');
  const propsNew = { ...props, to };

  return (
    <Link {...propsNew} activeClassName="active" >
      { props.children}
    </Link >
  )
}