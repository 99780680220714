import React from "react"
import PropTypes from "prop-types"

import MainLogo from "../assets/svg/main-logo.svg"

import { Normalize } from "styled-normalize"
import { ThemeProvider } from "styled-components"

import { GlobalStyles } from "../styles/global.styles"
import { AllThemes } from "../styles/theme.styles"

import "../styles/2012-XEPXRA.css"
import "../styles/ben-tempest.css"
import "../styles/fonts.css"

import {
  usePreloaderDispatch,
  usePreloaderState,
} from "../context/global-preloader-context"

import Header from "./header"
import Footer from "./footer"
import { ModalGroup } from "./modal-group"

const Layout = ({
  preloader,
  children,
  headerColor,
  removeMainClass,
  logo = "default",
  onAccept,
  modalStateOverride = false,
}) => {
  const setLoading = usePreloaderDispatch()
  const { preloaded } = usePreloaderState()

  React.useEffect(() => {
    const timer = setTimeout(() => {
      // TODO: Adjust depending on preloader implementation
      setLoading({ type: "FINISH_LOADING" })
    }, 1000)
    return () => clearTimeout(timer)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ThemeProvider theme={AllThemes[`default`]}>
        {/* <!-- Google Tag Manager (noscript) --> */}
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-TRR4Z29"
            style={{ display: "none", visibility: "hidden" }}
            height="0"
            width="0"
          ></iframe>
        </noscript>
        {/* <!-- End Google Tag Manager (noscript) --> */}

        {/* <script type="application/javascript" src="https://brevanhoward.profundcom.net/dms/w.php" referrerpolicy="no-referrer-when-downgrade"></script> */}

        {preloader === true && (
          <div className={`preloader ${preloaded === true ? "" : "hidden"}`}>
            <MainLogo />
            <div className="loading-bar"></div>
          </div>
        )}

        <Normalize />
        <GlobalStyles />

        <ModalGroup onAccept={onAccept} />

        <Header
          headerColor={headerColor}
          logo={logo}
          modalStateOverride={modalStateOverride}
        />

        <main className={`${removeMainClass ? "" : "site-main"}`}>
          {children}
        </main>

        <Footer />
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
