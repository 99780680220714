export const AllThemes = {
  breakpoints: {
    fhd: 1925,
    alg: 1700,
    mac: 1440,
    lt: 1366,
    ls: 1280,
    lg: 1199,
    crt: 1024,
    md: 1024,
    mds: 900,
    sm: 767,
    sf: 640,
    mb: 480,
    ip: 420,
    mob: 375,
    xs: 350,
    cont: 1518,
  },
  initialDlealy: 420,
  default: {
    fontshift: "0.19em",
    colors: {
      primary: "#141C25",
      primary100: "#21293E",
      primary200: "#434C60",
      primary300: "#1E2630",
      primary400: "#6D7D8F",
      secondary: "#9D968C",
      secondary50: "#BDB4A8",
      secondary100: "#D1C8BA",
      secondary200: "#ECE9E5",
      secondary300: "#F6F5F2",
      secondary400: "#9B9EA7",
      green: "#159545",
      green100: "#56BBB1",
      red: "#f03e34",
      gray: "#C4C4C4",
      gray100: "#7D7D7D",
      gray200: "#E5E5E5",
      white: "#ffffff",
      black: "#000000",
    },
    dimensions: {
      headerHeightDt: 140,
      cutoutWpos: 52,
    },
    fonts: {
      primary: "'RT Rondelle', sans-serif",
      primary100: "'RT Rondelle', sans-serif",
      secondary: "'Wulkan Display', serif",
      tertiary: "'Canela', serif",
    },
    container: 1700,
    dur: 400,
    easeCurve: `cubic-bezier(0.000, 0.000, 0.580, 1.000)`,
    bouncyCurve: `cubic-bezier(0.570, -0.450, 0.370, 1.440)`,
    max: function (bp, cb, props) {
      return `@media (max-width: ${AllThemes.breakpoints[bp]}px) {
        ${cb(props)}
      }`
    },
    min: function (bp, cb, props) {
      return `@media (min-width: ${AllThemes.breakpoints[bp] + 1}px) {
        ${cb(props)}
      }`
    },
  },
}

export const easeInOutS = `cubic-bezier(0.345, 0.005, 0.185, 0.995)`
