import React from "react"

import MainLogo from "../assets/svg/main-logo.svg"

const HeaderLogo = () => {
  return (
    <MainLogo />
  )
}

export default HeaderLogo