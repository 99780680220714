import React from "react"
import AngleCRight from "../assets/svg/angle-c-right.svg"

export const BtnInner = ({ text, delay = 600 }) => {
  return (
    <React.Fragment>
      <span className="btn-txt" style={{ transitionDelay: `${delay}ms` }}>{text}</span>
      <span className="svg-wrap" style={{ transitionDelay: `${delay}ms` }}><AngleCRight /></span>
    </React.Fragment>
  )
}