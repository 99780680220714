import styled from "styled-components"

import { col, containerSideOffset, SectionTitleMain } from "../common.styles"

export const ContactList = styled.div`
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.list-container-contact {
  li:nth-child(2) ul {
    li:nth-child(4) {
      margin-bottom: 1.63em;
    }
  }
  li:nth-child(3) ul,
  li:nth-child(4) ul {
    li {
      margin-bottom: 1.63em;
    }
  }

  .list-left {
    ${containerSideOffset()}
    ${col(34)}

    ${props => props.theme.max('md', () => (`
      ${col(45)}
    `))}

    ${props => props.theme.max('sm', () => (`
      font-size: 20px;
      ${col(73)}
      line-height: 1.63;
    `))}
  }

  .list-right {
    ${col(29.2)}
    ${containerSideOffset('right')}

    ${props => props.theme.max('md', () => (`
      ${col(45)}
    `))}

    ${props => props.theme.max('sm', () => (`
      ${col(100)}
    `))}
  }

  a {
    text-decoration: none;
  }
}

.list-left {
  font-size: 22px;
}

.list-right {
  margin-left: auto;
  line-height: 1.63;
}

.list-container-modal {
  li:nth-child(3) ul {
    li:nth-child(3) {
      margin-bottom: 1.63em;
    }
  }

  .list-left {
    ${col(60)}
    padding-right: 15px;

    ${props => props.theme.max('sm', () => (`
      ${col(100)}
      margin-bottom: .1em;
    `))}
  }

  .list-right {
    ${col(40)}

    ${props => props.theme.max('sm', () => (`
      ${col(100)}
      margin-left: 0;
    `))}
  }

  .list-item {
    margin-bottom: 35px;
  }
}

a {
  color: ${props => props.theme.colors.primary200};
  vertical-align: initial;
  transition: 400ms;
  
  &:hover {
    opacity: .5;
  }
}

.list-item {
  margin-bottom: 51px;
}

${SectionTitleMain} {
  margin-bottom: 0.89em;

  ${props => props.theme.max('sm', () => (`
    margin-bottom: 1.25em;
  `))}
}

.sub-section-t {
  margin-bottom: .1em;
}
`

export const ContactInformationMain = styled.div`
padding: 150px 0 77px;
background-color: ${props => props.theme.colors.secondary300};

${props => props.theme.max('sm', () => (`
  padding: 50px 0 50px;
`))}

.title-col {
  ${containerSideOffset()}
  ${col(24 - 8, 'c')}

  ${props => props.theme.max('sm', () => (`
    ${col(100)}
  `))}
}

&.to-animate {
  .list-item {
    transform: translate(0, 50px);
    opacity: 0;
  }
}

&.to-animate:not(.is-in-vp) {
  .list-item {
    transition-delay: 0ms !important;
  }
}

&.to-animate.is-in-vp {
  .list-item {
    transition: ${800}ms;
    transform: translate(0);
    opacity: 1;
  }
}
`