import React from "react"

import { LocalLink } from "../elements/link"

import MacroSvg from "../assets/svg/macro.svg"
import RiskSvg from "../assets/svg/risk.svg"
import CollaborativeSvg from "../assets/svg/collaborative.svg"
import TalentSvg from "../assets/svg/talent.svg"
import TechnologySvg from "../assets/svg/technology.svg"
import ProductSvg from "../assets/svg/product.svg"

import InstagramSvg from "../assets/svg/instagram.svg"
import LinkedinSvg from "../assets/svg/linkedin.svg"

import AsymmetricSvg from "../assets/svg/asymmetric.svg"
import GrowthSvg from "../assets/svg/growth.svg"
import BlockchainSvg from "../assets/svg/blockchain.svg"
import GlobalNetworkSvg from "../assets/svg/global-network.svg"
import GovernanceSvg from "../assets/svg/governance.svg"

export const AppendLink = ({ text, endLink, endLinkText, type }) => {
  if (!endLink || !endLinkText) {
    return text
  }

  return (
    <React.Fragment>
      {`${text} `}
      {type === "email" && <a href={endLink}>{endLinkText}</a>}
      {type === "external" && <a href={endLink}>{endLinkText}</a>}
      {type !== "email" && type !== "external" && (
        <LocalLink to={endLink}>{endLinkText}</LocalLink>
      )}
      .
    </React.Fragment>
  )
}

export const WPTextarea = ({
  text,
  endLink,
  endLinkText,
  type,
  style = {},
}) => {
  // eslint-disable-next-line no-control-regex
  const newT = text
    .replace("\u2028", "")
    .replace(new RegExp("\r\n", "g"), "")
    .split("<br /><br />")

  return (
    <React.Fragment>
      {newT.map((item, i) => {
        const line = item.split("<br />")
        if (line.length > 1) {
          return (
            <p key={i} style={style}>
              {line.map((s, ndx) => (
                <React.Fragment key={ndx}>
                  <AppendLink
                    text={s}
                    endLink={endLink}
                    endLinkText={endLinkText}
                    type={type}
                  />
                  {React.createElement("br")}
                </React.Fragment>
              ))}
            </p>
          )
        } else {
          return (
            <p key={i} style={style}>
              <AppendLink
                text={line[0] || ""}
                endLink={endLink}
                endLinkText={endLinkText}
                type={type}
              />
            </p>
          )
        }
      })}
    </React.Fragment>
  )
}

export const SplitText = ({
  text = "",
  type = "span",
  multiWrap,
  stInitialD = 400,
}) => {
  // eslint-disable-next-line no-control-regex
  if(text== undefined) {
    return  <React.Fragment></React.Fragment>;
  }

  const newT = text
    .replace("\u2028", "")
    .replace(new RegExp("\r\n", "g"), "")
    .split("<br />")
    .filter(val => val !== "")
  if (multiWrap === 1) {
    const stStepD = 100

    return (
      <React.Fragment>
        <span className="p-wrap">
          {newT.map((item, i) => (
            <span key={i} className="m-wrap">
              {React.createElement(
                type,
                {
                  className: "m-wrap",
                  style: { transitionDelay: `${stInitialD + stStepD * i}ms` },
                },
                `${item} `
              )}
            </span>
          ))}
        </span>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {newT.map((item, i) => React.createElement(type, { key: i }, `${item} `))}
    </React.Fragment>
  )
}

export function SvgIcon({ icon }) {
  const icons = {
    macro: MacroSvg,
    risk: RiskSvg,
    collaborative: CollaborativeSvg,
    talent: TalentSvg,
    technology: TechnologySvg,
    product: ProductSvg,
    instagram: InstagramSvg,
    linkedin: LinkedinSvg,
    asymmetric: AsymmetricSvg,
    growth: GrowthSvg,
    blockchain: BlockchainSvg,
    globalNetwork: GlobalNetworkSvg,
    governance: GovernanceSvg,
  }

  const IconEl = icons[icon]

  return <IconEl />
}

export const attribufyString = string => {
  if(string !== null) {
    return string
    .trim()
    .replace(/ /g, "-")
    .replace(/([^A-Za-z[\]{}_.:-])\s?/g, "")
    .toLowerCase()
  }
  else {
    return;
  }
 
}

export const fallbackFile = (wpFile, staticFile) => {
  return staticFile ? `/documents/${staticFile}` : wpFile
}

export function processMenuArray(arr) {
  return arr.map(item => {
    item.external = item?.url.includes("http") ? true : false
    return item
  })
}

export function unoptimzeGatsbyImageSource(image) {
  const imgFluid = image?.localImageFile
    ? image?.localImageFile.childImageSharp.fluid
    : image?.fluid
  const img = image?.localImageFile?.childImageSharp || image
  return [
    imgFluid,
    {
      ...imgFluid,
      src: img.original.src,
      srcSet: `${img.original.src} ${img.original.width}w`,
      sizes: `(min-width: 1024px) ${img.original.width}px`,
      media: `(min-width: 1024px)`,
    },
  ]
}

export const seoClassPrefix = "ga-"

export const breakTitle = (string, limit = 15) => {
  const newT = string
    .replace("\u2028", "")
    .replace(new RegExp("<br />|\r\n", "g"), " ")
    .replace(new RegExp("  +", "g"), " ")
    .trim()
  const split = newT.split(" ")

  let newRows = [""]
  let row = 0
  split.forEach((word, i) => {
    const rowText = `${newRows[row]} ${word}`
    if (rowText.length > limit) {
      row++
      newRows.push(word)
    } else {
      newRows[row] = i == 0 ? word : rowText
    }
  })

  return newRows.join(" <br />")
}

export const atAGlanceTitleResponsive = [
  { breakpoint: 767, charLimit: 20 },
  { breakpoint: 480, charLimit: 10 },
]

export const processHexagonData = (sections = []) => {
  const data = sections.map((name, i) => {
    return {
      name: name,
      sectionNum: `0${1 + sections.slice(0, i).filter(a => !!a).length}`.slice(
        -2
      ),
    }
  })
  return data
}
