import React from "react"
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser"

import { SectionTextMain } from "../styles/common.styles"

import { SectionText } from "./section-text"

export const TextPageContent = ({ setCurrentStep = () => undefined, sectionsHeading, separateIntroContent, data, hideSectionNumbers }) => {
  const isSSR = typeof window === "undefined";
  const { introContent, sections } = data;
  const transform = (node, index) => {
    if (node.name === 'a') {
      const origin = !isSSR ? window.location.origin : '';
      const checkHref = (node.attribs.href || '').replace(origin, '');
      const targetState = checkHref === '/privacy-policy/' || checkHref === '/privacy-policy'
        ? 'privacy'
        : ((checkHref === '/terms-of-use/' || checkHref === '/terms-of-use')
          ? 'disclaimer'
          : ((checkHref === '/contact/' || checkHref === '/contact')
            ? 'contact'
            : ''));

      if (targetState !== '') {
        return (
          <a href={node.attribs.href} key={index} onClick={(e) => {
            e.preventDefault();
            setCurrentStep(targetState);
          }}>
            {node.children.map((cnode) =>
              convertNodeToElement(cnode, index, transform)
            )}
          </a>
        )
      }
    }
    return convertNodeToElement(node, index, transform);
  }


  return (
    <React.Fragment>

      <div className="modal-content modal-content-sm">
        {(introContent || []).map(({ title, text, contentType, wysiwyg }, index) => (
          <div key={index}>
            {title && (
              <h2 className={'section-title intro-s-title'}>
                {title}
              </h2>
            )}
            {contentType ? <SectionTextMain>{ReactHtmlParser(wysiwyg, { transform })}</SectionTextMain> : <SectionText text={text} />}
          </div>
        ))}

        {sectionsHeading && <h2 className="section-title intro-s-title uppercase">{sectionsHeading}</h2>}

        {(sections || []).map(({ title, text, contentType, wysiwyg }, index) => (
          <div key={index}>
            {title && <h2 className="section-title s-title">
              {`${!hideSectionNumbers ? `${index + 1}. ` : ''}${title}`}
            </h2>}
            {contentType ? <SectionTextMain>{ReactHtmlParser(wysiwyg, { transform })}</SectionTextMain> : <SectionText text={text} />}
          </div>
        ))}
      </div>
    </React.Fragment>
  )

}
