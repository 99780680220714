import React from "react"
import { SectionText } from "./section-text"

const SimpleModalContent = ({ items, hideSectionNumbers }) => {
  return (
    <div className="modal-content modal-content-sm">
      {(items || []).map(({ title, sections }, i) => (
        <div key={String(i)}>
          <h2 className={'intro-title'}>
            {title || ''}
          </h2>
          {(sections || []).map(({ title, text }, i) => (
            <div key={i}>
              {title && <h2 className="section-title">
                {`${!hideSectionNumbers ? `${index + 1}.` : ''}${title}`}
              </h2>}
              <SectionText text={text} />
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default SimpleModalContent