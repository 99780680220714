import React from "react"

import { WPTextarea } from "../helpers/common"
import ReactHtmlParser from "react-html-parser"

import { SectionTextMain } from "../styles/common.styles"

export const SectionText = ({ text, variation, endLink, endLinkText, delay = 400, parse }) => {
  return (
    <SectionTextMain className={variation} style={{ transitionDelay: `${delay}ms` }}>
      {parse === true ?
        ReactHtmlParser(text)
        :
        (<WPTextarea text={text} endLink={endLink} endLinkText={endLinkText} />)
      }
    </SectionTextMain>
  )
}
