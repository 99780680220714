import React, { useEffect } from "react"

import { SplitText, breakTitle } from "../helpers/common"

import { SectionTitleMain } from "../styles/common.styles"

export const SectionTitle = ({ text, variation, delay = 400, responsive = [] }) => {
  const [title, setTitle] = React.useState(text || '');

  function handleResize() {
    if (responsive.length !== 0) {
      if (typeof window !== "undefined") {
        const breakpoints = responsive.filter((a) => a.breakpoint > window.innerWidth).sort((a, b) => a.breakpoint > b.breakpoint ? 1 : -1);

        if (breakpoints.length) {
          setTitle(breakTitle(title, breakpoints[0]?.charLimit || text));
        } else {
          setTitle(text);
        }
      }
    }
  }

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <SectionTitleMain className={variation || ''}>
      <SplitText text={title} type="span" multiWrap={1} stInitialD={delay} />
    </SectionTitleMain>
  )
}